define("ember-power-calendar-utils/index", ["exports", "date-fns/locale", "date-fns/startOfWeek", "date-fns/format", "date-fns/getDay", "ember-power-calendar-utils/unlocalized", "ember-power-calendar-utils/format-util"], function (_exports, _locale, _startOfWeek2, _format, _getDay, _unlocalized, _formatUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "add", {
    enumerable: true,
    get: function get() {
      return _unlocalized.add;
    }
  });
  Object.defineProperty(_exports, "diff", {
    enumerable: true,
    get: function get() {
      return _unlocalized.diff;
    }
  });
  Object.defineProperty(_exports, "endOf", {
    enumerable: true,
    get: function get() {
      return _unlocalized.endOf;
    }
  });
  Object.defineProperty(_exports, "endOfWeek", {
    enumerable: true,
    get: function get() {
      return _unlocalized.endOfWeek;
    }
  });
  _exports.formatDate = formatDate;
  Object.defineProperty(_exports, "getDefaultLocale", {
    enumerable: true,
    get: function get() {
      return _unlocalized.getDefaultLocale;
    }
  });
  _exports.getWeekdays = getWeekdays;
  _exports.getWeekdaysMin = getWeekdaysMin;
  _exports.getWeekdaysShort = getWeekdaysShort;
  Object.defineProperty(_exports, "isAfter", {
    enumerable: true,
    get: function get() {
      return _unlocalized.isAfter;
    }
  });
  Object.defineProperty(_exports, "isBefore", {
    enumerable: true,
    get: function get() {
      return _unlocalized.isBefore;
    }
  });
  Object.defineProperty(_exports, "isBetween", {
    enumerable: true,
    get: function get() {
      return _unlocalized.isBetween;
    }
  });
  Object.defineProperty(_exports, "isSame", {
    enumerable: true,
    get: function get() {
      return _unlocalized.isSame;
    }
  });
  Object.defineProperty(_exports, "isoWeekday", {
    enumerable: true,
    get: function get() {
      return _unlocalized.isoWeekday;
    }
  });
  _exports.localeStartOfWeek = localeStartOfWeek;
  Object.defineProperty(_exports, "normalizeCalendarDay", {
    enumerable: true,
    get: function get() {
      return _unlocalized.normalizeCalendarDay;
    }
  });
  Object.defineProperty(_exports, "normalizeCalendarValue", {
    enumerable: true,
    get: function get() {
      return _unlocalized.normalizeCalendarValue;
    }
  });
  Object.defineProperty(_exports, "normalizeDate", {
    enumerable: true,
    get: function get() {
      return _unlocalized.normalizeDate;
    }
  });
  Object.defineProperty(_exports, "normalizeDuration", {
    enumerable: true,
    get: function get() {
      return _unlocalized.normalizeDuration;
    }
  });
  Object.defineProperty(_exports, "normalizeMultipleActionValue", {
    enumerable: true,
    get: function get() {
      return _unlocalized.normalizeMultipleActionValue;
    }
  });
  Object.defineProperty(_exports, "normalizeRangeActionValue", {
    enumerable: true,
    get: function get() {
      return _unlocalized.normalizeRangeActionValue;
    }
  });
  Object.defineProperty(_exports, "startOf", {
    enumerable: true,
    get: function get() {
      return _unlocalized.startOf;
    }
  });
  Object.defineProperty(_exports, "startOfWeek", {
    enumerable: true,
    get: function get() {
      return _unlocalized.startOfWeek;
    }
  });
  Object.defineProperty(_exports, "weekday", {
    enumerable: true,
    get: function get() {
      return _unlocalized.weekday;
    }
  });
  Object.defineProperty(_exports, "withLocale", {
    enumerable: true,
    get: function get() {
      return _unlocalized.withLocale;
    }
  });
  function formatDate(date, dateFormat) {
    var locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    /* date-fns now uses [Unicode Tokens]{@link https://date-fns.org/v2.2.1/docs/Unicode-Tokens} so the following flags are required:
       - useAdditionalDayOfYearTokens is required to use the YYYY and YY tokens for year
       - useAdditionalWeekYearTokens is required to use the DD and D tokens for day
     */
    var normalizedFormat = (0, _formatUtil.normalizeDateFormat)(dateFormat);
    if (locale && _locale.default[locale]) {
      return (0, _format.default)(date, normalizedFormat, {
        locale: _locale.default[locale],
        useAdditionalDayOfYearTokens: false,
        useAdditionalWeekYearTokens: false
      });
    } else {
      return (0, _format.default)(date, normalizedFormat, {
        useAdditionalDayOfYearTokens: false,
        useAdditionalWeekYearTokens: false
      });
    }
  }
  function getWeekdaysShort() {
    var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    if (locale && _locale.default[locale]) {
      var weekdaysShort = [];
      for (var i = 0; i < 7; i++) {
        weekdaysShort.push(_locale.default[locale].localize.day(i, {
          width: "abbreviated"
        }));
      }
      return weekdaysShort;
    } else {
      return (0, _unlocalized.getWeekdaysShort)();
    }
  }
  function getWeekdaysMin() {
    var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    if (locale && _locale.default[locale]) {
      var weekdaysMin = [];
      for (var i = 0; i < 7; i++) {
        weekdaysMin.push(_locale.default[locale].localize.day(i, {
          width: "narrow"
        }));
      }
      return weekdaysMin;
    } else {
      return (0, _unlocalized.getWeekdaysMin)();
    }
  }
  function getWeekdays() {
    var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    if (locale && _locale.default[locale]) {
      var weekdays = [];
      for (var i = 0; i < 7; i++) {
        weekdays.push(_locale.default[locale].localize.day(i, {
          width: "wide"
        }));
      }
      return weekdays;
    } else {
      return (0, _unlocalized.getWeekdays)();
    }
  }
  function localeStartOfWeek(locale) {
    if (locale && _locale.default[locale]) {
      return (0, _getDay.default)((0, _startOfWeek2.default)(new Date(), {
        locale: _locale.default[locale]
      }));
    } else {
      return (0, _unlocalized.localeStartOfWeek)();
    }
  }
});