define("ember-power-calendar-utils/format-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeDateFormat = normalizeDateFormat;
  // lookup table for faster conversion
  // power-calendar format -> date-fns format (see https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table)
  var knownFormats = {
    'YYYY-MM-DD': 'yyyy-MM-dd',
    'MMMM YYYY': 'MMMM yyyy',
    'MMMM': 'MMMM',
    'YYYY': 'yyyy'
  };
  function normalizeDateFormat(formatString) {
    if (knownFormats[formatString]) return knownFormats[formatString];

    // In other cases, we just pass the format as we don't know if the developer used the unicode tokens or not.
    // This might be misleading but we can't transform them by default to avoid breaking developer intentions.
    return formatString;
  }
});