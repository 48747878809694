define("ember-drag-drop/components/object-bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function removeOne(arr, obj) {
    var l = arr.get('length');
    arr.removeObject(obj);
    var l2 = arr.get('length');
    if (l - 1 !== l2) {
      throw "bad length " + l + " " + l2;
    }
  }
  var _default = Ember.Component.extend({
    model: Ember.A(),
    classNames: ['draggable-object-bin'],
    manageList: true,
    objectMoved: function objectMoved() {},
    actions: {
      handleObjectDropped: function handleObjectDropped(obj) {
        if (this.get('manageList')) {
          this.get("model").pushObject(obj);
        }
        this.trigger("objectDroppedInternal", obj);
        this.get('objectDropped')({
          obj: obj,
          bin: this
        });
      },
      handleObjectDragged: function handleObjectDragged(obj) {
        if (this.get('manageList')) {
          removeOne(this.get('model'), obj);
        }
        this.trigger("objectDraggedInternal", obj);
        this.get('objectDragged')();
      }
    }
  });
  _exports.default = _default;
});