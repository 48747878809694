define("ember-on-helper/helpers/on", ["exports", "@ember/component/helper", "ember-on-helper/utils/event-listener", "@ember/debug"], function (_exports, _helper, _eventListener, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.__counts = __counts;
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /**
   * These are private API and only used for testing instrumentation.
   */
  var adds = 0;
  var removes = 0;
  function __counts() {
    return {
      adds: adds,
      removes: removes
    };
  }
  var assertValidEventOptions = false /* DEBUG */ && function () {
    var ALLOWED_EVENT_OPTIONS = ['capture', 'once', 'passive'];
    var joinOptions = function joinOptions(options) {
      return options.map(function (o) {
        return "'".concat(o, "'");
      }).join(', ');
    };
    return function (eventOptions, eventName) {
      var invalidOptions = Object.keys(eventOptions).filter(function (o) {
        return !ALLOWED_EVENT_OPTIONS.includes(o);
      });
      (false && !(invalidOptions.length === 0) && (0, _debug.assert)("ember-on-helper: Provided invalid event options (".concat(joinOptions(invalidOptions), ") to '").concat(eventName, "' event listener. Only these options are valid: ").concat(joinOptions(ALLOWED_EVENT_OPTIONS)), invalidOptions.length === 0));
    };
  }();
  function setupListener(eventTarget, eventName, callback, eventOptions) {
    if (false /* DEBUG */) assertValidEventOptions(eventOptions, eventName);
    (false && !(eventTarget && typeof eventTarget.addEventListener === 'function' && typeof eventTarget.removeEventListener === 'function') && (0, _debug.assert)("ember-on-helper: '".concat(eventTarget, "' is not a valid event target. It has to be an Element or an object that conforms to the EventTarget interface."), eventTarget && typeof eventTarget.addEventListener === 'function' && typeof eventTarget.removeEventListener === 'function'));
    (false && !(typeof eventName === 'string' && eventName.length > 1) && (0, _debug.assert)("ember-on-helper: '".concat(eventName, "' is not a valid event name. It has to be a string with a minimum length of 1 character."), typeof eventName === 'string' && eventName.length > 1));
    (false && !(typeof callback === 'function') && (0, _debug.assert)("ember-on-helper: '".concat(callback, "' is not a valid callback. Provide a function."), typeof callback === 'function'));
    adds++;
    (0, _eventListener.addEventListener)(eventTarget, eventName, callback, eventOptions);
    return callback;
  }
  function destroyListener(eventTarget, eventName, callback, eventOptions) {
    if (eventTarget && eventName && callback) {
      removes++;
      (0, _eventListener.removeEventListener)(eventTarget, eventName, callback, eventOptions);
    }
  }
  var _default = _helper.default.extend({
    eventTarget: null,
    eventName: undefined,
    callback: undefined,
    eventOptions: undefined,
    compute: function compute(_ref, eventOptions) {
      var _ref2 = _slicedToArray(_ref, 3),
        eventTarget = _ref2[0],
        eventName = _ref2[1],
        callback = _ref2[2];
      destroyListener(this.eventTarget, this.eventName, this.callback, this.eventOptions);
      this.eventTarget = eventTarget;
      this.callback = setupListener(this.eventTarget, eventName, callback, eventOptions);
      this.eventName = eventName;
      this.eventOptions = eventOptions;
    },
    willDestroy: function willDestroy() {
      this._super();
      destroyListener(this.eventTarget, this.eventName, this.callback, this.eventOptions);
    }
  });
  _exports.default = _default;
});