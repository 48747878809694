define("fimab-2d-view/cabinet", ["exports", "fimab-2d-view/math", "fimab-2d-view/component", "fimab-2d-view/types"], function (_exports, _math, _component, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sizeCabinetSvg = _exports.isInsideCabinetSurface = _exports.getTotalComponentInstanceCount = _exports.getChangedCabinetConfiguration = _exports.getCabinetSide = _exports.exportCabinet = _exports.drawCabinetSVG = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  /**
   * Liefert true, falls das übergebene Rect vollständig innerhalb der Schrankfläche ist. Funktion
   * berücksichtigt keine evtl. vorhandenen gesperrten Bereiche!
   *
   * @param surface
   * @param rect
   */
  var isInsideCabinetSurface = function isInsideCabinetSurface(surface, rect) {
    return (0, _math.isContained)(rect, {
      x: surface.minX,
      y: surface.minY,
      w: surface.maxX - surface.minX,
      h: surface.maxY - surface.minY,
      r: 0
    });
  };

  /**
   * Liefert die Gesamtanzahl aller Komponenten auf allen Schrankseiten
   *
   * @param cabinet
   */
  _exports.isInsideCabinetSurface = isInsideCabinetSurface;
  var getTotalComponentInstanceCount = function getTotalComponentInstanceCount(cabinet) {
    var c = 0;
    cabinet.sides.forEach(function (side) {
      c += side.components.length;
    });
    return c;
  };

  /**
   * Liefert die aktuelle Schrankkonfiguration, die von der Konfigurator-App verarbeitet wird
   *
   * @param cabinet
   * @param side
   */
  _exports.getTotalComponentInstanceCount = getTotalComponentInstanceCount;
  var getChangedCabinetConfiguration = function getChangedCabinetConfiguration(cabinet, side) {
    return _objectSpread(_objectSpread({}, cabinet), {}, {
      sides: cabinet.sides.map(function (cabinetSide) {
        // [TBD] Handling der Montageplatte (0,0 bezieht sich auf 0,0 der)
        if (cabinetSide.type === side) {
          var _instances = cabinetSide.components.map(function (instance) {
            var i = _objectSpread({}, instance);
            delete i.element;
            delete i.contentElement;
            return i;
          });
          return _objectSpread(_objectSpread({}, cabinetSide), {}, {
            components: _instances
          });
        } else {
          return cabinetSide;
        }
      })
    });
  };

  /**
   * Liefert die angegebene Schrankseite des übergebenen Schranks
   *
   * @param cabinet
   * @param side
   */
  _exports.getChangedCabinetConfiguration = getChangedCabinetConfiguration;
  var getCabinetSide = function getCabinetSide(cabinet, side) {
    return cabinet.sides.find(function (config) {
      return config.type === side;
    });
  };

  /**
   * Errechnet die optimalen Dimensionen um die ausgewählte Schrankseite zu zeichnen.
   * Diese Funktion muss bei jedem Schrankseitenwechsel oder einem resize Event aufgerufen werden.
   * Der Offset, Breite, Höhe und Skalierungsfaktor des SVG-Containers werden in der
   * cabinetSurfaceRect Property gespeichert.
   *
   * @param side
   */
  _exports.getCabinetSide = getCabinetSide;
  var sizeCabinetSvg = function sizeCabinetSvg(surface, board) {
    var container = board.find('.fimab-board__container');
    var availWidth = container.innerWidth();
    var availHeight = container.innerHeight() * 0.98;
    var requiredWidth = surface.width;
    var requiredHeight = surface.height;
    var ratioWidth = availWidth / requiredWidth;
    var ratioHeight = availHeight / requiredHeight;
    var cabinetSurfaceElement = board.find('.fimab-board__cabinetSurface').parent();
    var ratio;
    var width;
    var height;
    if (ratioWidth < ratioHeight) {
      width = availWidth;
      height = requiredHeight * ratioWidth;
      ratio = ratioWidth;
    } else {
      width = requiredWidth * ratioHeight;
      height = availHeight;
      ratio = ratioHeight;
    }
    // console.log('availWidth', availWidth, 'availHeight', availHeight, 'requiredWidth', requiredWidth, 'requiredHeight', requiredHeight, 'finalW', width, 'finalHeight', height, 'ratio', ratio);
    cabinetSurfaceElement.outerHeight(height).outerWidth(width);
    var cOffset = cabinetSurfaceElement.offset();
    var rect = {
      x: cOffset.left /*  + parseInt(cabinetSurfaceElement.css('marginLeft'))*/,
      y: cOffset.top /*  + parseInt(cabinetSurfaceElement.css('marginTop')) */,
      w: width,
      h: height,
      ratio: ratio,
      r: 0
    };
    return rect;
  };

  /**
   * Zeichnet die ausgewählte Schrankoberfläche mit SVG im übergebenenen Element.
   *
   * @param cabinet
   * @param side
   * @param element
   */
  _exports.sizeCabinetSvg = sizeCabinetSvg;
  var drawCabinetSVG = function drawCabinetSVG(cabinet, side, element) {
    var surface = getCabinetSide(cabinet, side);
    if (!surface) {
      throw "Schrankseite ".concat(side, " nicht gefunden");
    }
    // Inhalte von SVG Container loeschen
    while (element.childNodes.length) {
      element.removeChild(element.childNodes[0]);
    }
    var w = surface.width;
    var h = surface.height;
    if (isNaN(surface.height) || surface.height < 0 || isNaN(surface.width) || surface.width < 0 || surface.minX < 0 || surface.maxX < 0 || surface.minY < 0 || surface.maxY < 0) {
      return;
    }

    // @ts-ignore
    var draw = SVG(element).viewbox(0, 0, w, h).size(w, h);

    // Schrank außen mit Sperrfläche

    draw.rect(w, h).addClass('fimab-svg__cabinetSurfaceLockArea');
    var lockRect = surface.lockArea;

    // Freie Schrankoberfläche

    if (lockRect) {
      draw.rect(lockRect.x - surface.minX, surface.maxY - surface.minY).addClass('fimab-svg__availableSurface').move(surface.minX, surface.minY);
      draw.rect(surface.maxX - lockRect.x - lockRect.w, surface.maxY - surface.minY).addClass('fimab-svg__availableSurface').move(lockRect.x + lockRect.w, surface.minY);
    } else if (side == "OBEN") {
      draw.rect(surface.maxX - surface.minX, surface.maxY - surface.minY).addClass('fimab-svg__availableSurface').move(surface.minX, h - surface.maxY);
      draw.rect(w, 25) // Tür darstellen
      .addClass('fimab-svg__door').move(0, h - 25).fill('#7b7b7b');
    } else if (side == "UNTEN") {
      draw.rect(surface.maxX - surface.minX, surface.maxY - surface.minY).addClass('fimab-svg__availableSurface').move(surface.minX, h - surface.maxY);
      draw.rect(w, 25) // Tür darstellen
      .addClass('fimab-svg__door').move(0, 0).fill('#7b7b7b');
    } else {
      draw.rect(surface.maxX - surface.minX, surface.maxY - surface.minY).addClass('fimab-svg__availableSurface').move(surface.minX, surface.minY);
    }
    if (side == "LINKS") {
      draw.rect(25, h) // Tür darstellen
      .addClass('fimab-svg__door').move(w - 25, 0).fill('#7b7b7b');
    }
    if (side == "RECHTS") {
      draw.rect(25, h) // Tür darstellen
      .addClass('fimab-svg__door').move(0, 0).fill('#7b7b7b');
    }

    // Scharniere und Türgriff
    if (side === _types.CubeSides.FRONT) {
      var sh = 50;
      var hingeWidth = 10;
      var hingeStrokeAttrs = {
        width: hingeWidth,
        color: '#7b7b7b',
        linecap: 'round'
      };
      var handleStrokeAttrs = {
        width: 40,
        color: '#7b7b7b',
        linecap: 'round'
      };
      var holeThingamajongFill = {
        fill: '#7b7b7b'
      };
      if (cabinet.model === _types.CabinetModels.MODEL_1_RIGHT) {
        draw.line(40, h / 2 - 50, 40, h / 2 + 50).strokehandleStrokeAttrs;
        draw.line(w - hingeWidth, 10, w - hingeWidth, 10 + sh).stroke(hingeStrokeAttrs);
        draw.line(w - hingeWidth, h - sh - 10, w - hingeWidth, h - 10).stroke(hingeStrokeAttrs);
        draw.line(w - hingeWidth, h / 2 - sh / 2, w - hingeWidth, h / 2 + sh / 2).stroke(hingeStrokeAttrs);
      } else if (cabinet.model === _types.CabinetModels.MODEL_1_LEFT) {
        draw.line(surface.width - 40, h / 2 - 50, surface.width - 40, h / 2 + 50).stroke(handleStrokeAttrs);
        draw.line(hingeWidth, 10, hingeWidth, 10 + sh).stroke(hingeStrokeAttrs);
        draw.line(hingeWidth, h - sh - 10, hingeWidth, h - 10).stroke(hingeStrokeAttrs);
        draw.line(hingeWidth, h / 2 - sh / 2, hingeWidth, h / 2 + sh / 2).stroke(hingeStrokeAttrs);
      } else if (cabinet.model === _types.CabinetModels.MODEL_2) {
        draw.line(hingeWidth, 10, hingeWidth, 10 + sh).stroke(hingeStrokeAttrs);
        draw.line(hingeWidth, h - sh - 10, hingeWidth, h - 10).stroke(hingeStrokeAttrs);
        draw.line(w - hingeWidth, 10, w - hingeWidth, 10 + sh).stroke(hingeStrokeAttrs);
        draw.line(w - hingeWidth, h - sh - 10, w - hingeWidth, h - 10).stroke(hingeStrokeAttrs);
        draw.circle(20).attr(holeThingamajongFill).move(lockRect.x + lockRect.w - 35, 40); // oberer Knopf
        draw.circle(20).attr(holeThingamajongFill).move(lockRect.x + lockRect.w - 35, h - 60); // unterer Knopf
        console.log("h", h, "surface.minY" + surface.minY);
      } else if (cabinet.model === _types.CabinetModels.MODEL_3) {
        draw.circle(10).attr(holeThingamajongFill).move(10, 10);
        draw.circle(10).attr(holeThingamajongFill).move(w - 10 - 10, 10);
        draw.circle(10).attr(holeThingamajongFill).move(w - 10 - 10, h - 10 - 10);
        draw.circle(10).attr(holeThingamajongFill).move(10, h - 10 - 10);
      }
    }
  };

  /**
   * Liefert die Schrankkonfiguration, die vom 3D-View verarbeitet wird.
   *
   * @param cabinet
   */
  _exports.drawCabinetSVG = drawCabinetSVG;
  var exportCabinet = function exportCabinet(cabinet) {
    var exportCabinet = _objectSpread(_objectSpread({}, cabinet), {}, {
      sides: cabinet.sides.map(function (cabinetSurface) {
        return _objectSpread(_objectSpread({}, cabinetSurface), {}, {
          components: cabinetSurface.components.map(function (component) {
            return (0, _component.transformComponentForExport)(component, cabinetSurface);
          })
        });
      })
    });
    return exportCabinet;
  };
  _exports.exportCabinet = exportCabinet;
});