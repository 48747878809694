define("ember-changeset/utils/computed/object-equal", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = objectEqual;
  var keys = Object.keys;
  /**
   * Shallow object comparison computed property. Checks all key/value pairs on
   * the first object and compares against the second object. Essentially, this
   * means that the second object must have the same key/values as the first, but
   * not vice versa.
   *
   * @public
   * @param  {string} sourceKey dependent key for first object
   * @param  {string} compareKey dependent key for second object
   * @return {boolean}
   */
  function objectEqual(sourceKey, compareKey) {
    return (0, _object.computed)(sourceKey, compareKey, function () {
      var source = (0, _object.get)(this, sourceKey);
      var compare = (0, _object.get)(this, compareKey);
      return keys(source).reduce(function (acc, key) {
        return acc && (0, _object.get)(source, key) === (0, _object.get)(compare, key);
      }, true);
    }).readOnly();
  }
});