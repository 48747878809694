define("ember-changeset-validations/utils/validation-errors", ["exports", "@ember/object", "@ember/debug", "@ember/polyfills", "ember-get-config", "ember-changeset-validations/utils/get-messages", "@ember/utils"], function (_exports, _object, _debug, _polyfills, _emberGetConfig, _getMessages, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildMessage;
  /**
   * For code taken from ember-cp-validations
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  function buildMessage(key, result) {
    var returnsRaw = (0, _object.getWithDefault)(_emberGetConfig.default, 'changeset-validations.rawOutput', false);
    var messages = (0, _getMessages.default)();
    var description = messages.getDescriptionFor(key);
    if (result.message) {
      return result.message;
    }
    var type = result.type,
      value = result.value,
      _result$context = result.context,
      context = _result$context === void 0 ? {} : _result$context;
    if (context.message) {
      var _message = context.message;
      if ((0, _utils.typeOf)(_message) === 'function') {
        var builtMessage = _message(key, type, value, context);
        (false && !((0, _utils.typeOf)(builtMessage) === 'string') && (0, _debug.assert)('Custom message function must return a string', (0, _utils.typeOf)(builtMessage) === 'string'));
        return builtMessage;
      }
      return messages.formatMessage(_message, (0, _polyfills.assign)({
        description: description
      }, context));
    }
    var message = (0, _object.get)(messages, type);
    if (returnsRaw) {
      context = (0, _polyfills.assign)({}, context, {
        description: description
      });
      return {
        value: value,
        type: type,
        message: message,
        context: context
      };
    } else {
      return messages.formatMessage(message, (0, _polyfills.assign)({
        description: description
      }, context));
    }
  }
});