define("ember-component-css/utils/init-route-styles", ["exports", "ember-component-css/pod-names"], function (_exports, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = initRouteStyles;
  function initRouteStyles(owner, routes) {
    var classes = [];
    for (var i = 0; i < routes.length; i++) {
      var route = routes[i];
      var currentPath = route.name.replace(/\./g, '/');
      if (_podNames.default[currentPath]) {
        owner.lookup("controller:".concat(route.name)).set('styleNamespace', _podNames.default[currentPath]);
        classes.push(_podNames.default[currentPath]);
      }
    }
    owner.lookup('controller:application').set('routeStyleNamespaceClassSet', classes.join(' '));
  }
});