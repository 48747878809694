define("ember-component-css/pod-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "app-cabins": "__61814",
    "app-calculation": "__f2ceb",
    "app-configurator-bar": "__c2dd4",
    "app-nav": "__f5f63",
    "company-card": "__136d8",
    "company-invite-card": "__8eca2",
    "company-result-card": "__8ae37"
  };
  _exports.default = _default;
});