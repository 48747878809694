define("ember-power-calendar-utils/unlocalized", ["exports", "date-fns/addDays", "date-fns/addMonths", "date-fns/addWeeks", "date-fns/differenceInMilliseconds", "date-fns/endOfDay", "date-fns/endOfISOWeek", "date-fns/endOfMonth", "date-fns/format", "date-fns/getDay", "date-fns/getISODay", "date-fns/isSameDay", "date-fns/startOfDay", "date-fns/startOfISOWeek", "date-fns/startOfMonth", "date-fns/isAfter", "date-fns/isBefore", "date-fns/startOfWeek", "ember-power-calendar-utils/format-util"], function (_exports, _addDays, _addMonths, _addWeeks, _differenceInMilliseconds, _endOfDay, _endOfISOWeek, _endOfMonth, _format, _getDay, _getISODay, _isSameDay, _startOfDay, _startOfISOWeek, _startOfMonth, _isAfter2, _isBefore2, _startOfWeek2, _formatUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.diff = diff;
  _exports.endOf = endOf;
  _exports.endOfWeek = endOfWeek;
  _exports.formatDate = formatDate;
  _exports.getDefaultLocale = getDefaultLocale;
  _exports.getWeekdays = getWeekdays;
  _exports.getWeekdaysMin = getWeekdaysMin;
  _exports.getWeekdaysShort = getWeekdaysShort;
  _exports.isAfter = isAfter;
  _exports.isBefore = isBefore;
  _exports.isBetween = isBetween;
  _exports.isSame = isSame;
  _exports.isoWeekday = isoWeekday;
  _exports.localeStartOfWeek = localeStartOfWeek;
  _exports.normalizeCalendarDay = normalizeCalendarDay;
  _exports.normalizeCalendarValue = normalizeCalendarValue;
  _exports.normalizeDate = normalizeDate;
  _exports.normalizeDuration = normalizeDuration;
  _exports.normalizeMultipleActionValue = normalizeMultipleActionValue;
  _exports.normalizeRangeActionValue = normalizeRangeActionValue;
  _exports.startOf = startOf;
  _exports.startOfWeek = startOfWeek;
  _exports.weekday = weekday;
  _exports.withLocale = withLocale;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function unsupported(method, param) {
    for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      args[_key - 2] = arguments[_key];
    }
    throw "unsupported parameter: ".concat(method, "(").concat(args.join(','), ")");
  }
  function add(date, quantity, unit) {
    switch (unit) {
      case "day":
        return (0, _addDays.default)(date, quantity);
      case "month":
        return (0, _addMonths.default)(date, quantity);
      default:
        throw unsupported.apply(void 0, ['add'].concat(Array.prototype.slice.call(arguments)));
    }
  }
  function formatDate(date, dateFormat) {
    var normalizedFormat = (0, _formatUtil.normalizeDateFormat)(dateFormat);
    return (0, _format.default)(date, normalizedFormat, {
      useAdditionalDayOfYearTokens: false,
      useAdditionalWeekYearTokens: false
    });
  }
  function startOf(date, unit) {
    switch (unit) {
      case "month":
        return (0, _startOfMonth.default)(date);
      case "isoWeek":
        return (0, _startOfISOWeek.default)(date);
      case "week":
        return (0, _startOfWeek2.default)(date);
      case "day":
        return (0, _startOfDay.default)(date);
      default:
        throw unsupported.apply(void 0, ['startOf'].concat(Array.prototype.slice.call(arguments)));
    }
  }
  function endOf(date, unit) {
    switch (unit) {
      case "month":
        return (0, _endOfMonth.default)(date);
      case "isoWeek":
        return (0, _endOfISOWeek.default)(date);
      case "day":
        return (0, _endOfDay.default)(date);
      default:
        throw unsupported.apply(void 0, ['endOf'].concat(Array.prototype.slice.call(arguments)));
    }
  }
  function weekday(date) {
    return (0, _getDay.default)(date);
  }
  function isoWeekday(date) {
    return (0, _getISODay.default)(date);
  }
  function getWeekdaysShort() {
    if (false /* DEBUG */) {
      console.debug("Calling `getWeekdaysShort` with ember-power-calendar-date-fns is discouraged as date-fns has no locale detection implemented. " + "Please overwrite the power-calendar days component `weekdaysShort` method.");
    }
    return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  }
  function getWeekdaysMin() {
    if (false /* DEBUG */) {
      console.debug("Calling `getWeekdaysMin` with ember-power-calendar-date-fns is discouraged as date-fns has no locale detection implemented. " + "Please overwrite the power-calendar days component `weekdaysMin` method.");
    }
    return ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  }
  function getWeekdays() {
    if (false /* DEBUG */) {
      console.debug("Calling `getWeekdays` with ember-power-calendar-date-fns is discouraged as date-fns has no locale detection implemented. " + "Please overwrite the power-calendar days component `weekdays` method.");
    }
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  }
  function isAfter(date1, date2) {
    return (0, _isAfter2.default)(date1, date2);
  }
  function isBefore(date1, date2) {
    return (0, _isBefore2.default)(date1, date2);
  }
  function isSame(date1, date2, unit) {
    switch (unit) {
      case "day":
        return (0, _isSameDay.default)(date1, date2);
      default:
        throw unsupported.apply(void 0, ['isSame'].concat(Array.prototype.slice.call(arguments)));
    }
  }
  function isBetween(date, start, end) {
    // taken from calendar-luxon
    return +start <= +date && +date <= +end;
  }
  function diff(date1, date2) {
    return (0, _differenceInMilliseconds.default)(date1, date2);
  }
  function normalizeDate(date) {
    // as everything is a Date, return it
    return date;
  }
  function normalizeRangeActionValue(val) {
    return {
      date: val.date,
      _date: {
        start: val.date.start,
        end: val.date.end
      }
    };
  }
  function normalizeMultipleActionValue(val) {
    return {
      date: val.date,
      _date: val.date
    };
  }
  function normalizeCalendarDay(day) {
    day._date = new Date(day.date);
    return day;
  }
  function withLocale(locale, fn) {
    return fn(locale);
  }
  function normalizeCalendarValue(value) {
    if (value) {
      return {
        date: value.date,
        _date: value.date ? value.date : undefined
      };
    }
    return {
      date: undefined,
      _date: undefined
    };
  }
  function normalizeDuration(value) {
    if (value === null) {
      return null;
    }
    if (value instanceof Date) {
      return value.getTime();
    }
    if (typeof value === "number") {
      return value;
    }
    if (typeof value === "string") {
      var _value$match = value.match(/(\d+)(.*)/),
        _value$match2 = _slicedToArray(_value$match, 3),
        quantity = _value$match2[1],
        units = _value$match2[2];
      units = units.trim() || "days";
      var quantityNumber = parseInt(quantity, 10);
      var a = new Date();
      var b;
      switch (units) {
        case "days":
          {
            b = (0, _addDays.default)(a, quantityNumber);
            break;
          }
        case "w":
        case "week":
          {
            b = (0, _addWeeks.default)(a, quantityNumber);
            break;
          }
        default:
          unsupported("normalizeDuration", value);
      }
      return diff(a, b);
    }
  }
  function getDefaultLocale() {
    if (false /* DEBUG */) {
      console.debug("Calling `getDefaultLocale` with ember-power-calendar-date-fns is discouraged as date-fns has no locale detection implemented. " + "Please overwrite the power-calendar service and set your own locale");
    }
    return 'en';
  }
  function localeStartOfWeek() {
    if (false /* DEBUG */) {
      console.debug("Can't detect start of week automatically. Please configure `startOfWeek` in `calendar.days`. See https://ember-power-calendar.com/docs/the-days");
    }
    return 0;
  }
  function startOfWeek(day, startOfWeek) {
    while (isoWeekday(day) % 7 !== startOfWeek) {
      day = add(day, -1, "day");
    }
    return day;
  }
  function endOfWeek(day, startOfWeek) {
    var eow = (startOfWeek + 6) % 7;
    while (isoWeekday(day) % 7 !== eow) {
      day = add(day, 1, "day");
    }
    return day;
  }
});