define("fimab-2d-view/util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sanitizeUserInput = _exports.isUndefined = _exports.isNil = _exports.isMacOs = _exports.isCommandOrCtrlKey = void 0;
  var sanitizeUserInput = function sanitizeUserInput(value, oldValue) {
    var _value;
    if (typeof value === 'string') {
      // Umwandlung Dezimalkomma (DE) in Dezimalpunkt (JS)
      _value = value.replace(/,/g, '.');
    }
    if (isNaN(_value)) {
      try {
        // eval() ist eine einfache Möglichkeit mit arithmetrischen Ausdrücken umzugehen
        _value = Number(eval(String(value)));
      } catch (error) {
        // Fallback falls Ausdruck fehlerhaft
        _value = Number(oldValue);
      }
    } else {
      _value = _value;
    }
    return _value;
  };
  _exports.sanitizeUserInput = sanitizeUserInput;
  var isMacOs = function isMacOs() {
    return navigator.userAgent.toLowerCase().indexOf('mac') > -1;
  };
  _exports.isMacOs = isMacOs;
  var isCommandOrCtrlKey = function isCommandOrCtrlKey(event) {
    return isMacOs() ? event.metaKey : event.ctrlKey;
  };
  _exports.isCommandOrCtrlKey = isCommandOrCtrlKey;
  var isUndefined = function isUndefined(v) {
    return typeof v === 'undefined';
  };
  _exports.isUndefined = isUndefined;
  var isNil = function isNil(v) {
    return isUndefined(v) || v === null;
  };
  _exports.isNil = isNil;
});