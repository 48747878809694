define("fimab-2d-view/components/fimab-unityview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0d+0IKy9",
    "block": "[[[10,0],[14,0,\"webgl-content\"],[12],[1,\"\\n  \"],[10,0],[14,1,\"unityContainer\"],[14,5,\"width: 960px; height: 600px\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"footer\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"fullscreen\"],[14,\"onclick\",\"unityInstance.SetFullscreen(1)\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "fimab-2d-view/components/fimab-unityview/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});