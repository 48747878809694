define('virtual-each/mixins/default-attrs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.defaultAttrs) {
        this.defaultAttrs = {};
      }
    },
    getAttr: function getAttr(attrName) {
      var value = this._super.apply(this, arguments);

      if (typeof value === 'undefined') {
        return this.get('defaultAttrs.' + attrName);
      }

      return value;
    }
  });
});