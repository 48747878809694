define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["de-de", {
    "AccountModal": {
      "Change": "Ändern",
      "Head1": "Konto Bearbeiten",
      "Head2": "Profilbild Bearbeiten",
      "Label1": "Firma",
      "Label2": "Account*",
      "Login": "Anmelden",
      "LogoutMessage": "Sie wurden abgemeldet. Hier können sie sich erneut anmelden:",
      "MainAcc": {
        "CreateAccount": "Account erstellen",
        "Error": "Error",
        "ForgotPassword": "Passwort vergessen?",
        "Head1": "Anmelden",
        "ResendPassword": "Wir haben Ihnen eine E-Mail zugesant, welche Instruktionen enthält, wie Sie Ihr Passwort zurücksetzen können.",
        "Success": "Erfolg"
      },
      "Remove": "Entfernen",
      "RequiredFields": "* Pflichtfelder",
      "Save": "Speichern",
      "UploadImage": "Bild hochladen"
    },
    "BurgerMenu": {
      "AccountInformation": "Kontoinformationen",
      "Company": "Firmenverwaltung",
      "DataProtection": "Datenschutz",
      "HelpAndSupport": {
        "Chat": "Support Chat",
        "FirewallSetting": "Firewall-Einstellungen",
        "Head1": "Hilfe und Support",
        "Mail": "E-Mail: armario@fimab.eu",
        "Tel": "Support Telefon: 07053 96839-65"
      },
      "Imprint": "Impressum",
      "Legal": {
        "DataProtection": "Datenschutz",
        "Header": "Rechtliches",
        "Imprint": "Impressum"
      },
      "Login": "Anmelden",
      "Logout": "Abmelden",
      "OrderHistory": "Bestellübersicht",
      "Support": {
        "FirewallSetting": "ARMARIO Firewall Einstellungen für Unternehmen",
        "Head1": "Support",
        "SuppChat": "Support Chat",
        "SuppMail1": "Support Mailadresse:",
        "SuppMail2": "armario@fimab.eu",
        "SuppTel1": "Support Telefonnummer: ",
        "SuppTel2": "+49 (7053) 96839-65"
      },
      "UserProfil": "Benutzerprofil"
    },
    "Company": {
      "CompanyCard": {
        "Leave": "Verlassen"
      },
      "Employees": {
        "Header": "Mitarbeiter"
      },
      "Header": "Ihre Firma",
      "OpenInvites": {
        "Filter": "Status Filter",
        "FilterOptions": {
          "Accepted": "Akzeptiert",
          "Declined": "Abgelehnt",
          "Open": "Offen"
        },
        "Header": "Offene Einladungen"
      },
      "OpenRequests": {
        "Filter": "Status Filter",
        "FilterOptions": {
          "Accepted": "Akzeptiert",
          "Declined": "Abgelehnt",
          "Open": "Offen"
        },
        "Header": "Offene Anfragen"
      },
      "Table": {
        "Accept": "Akzeptieren",
        "Actions": "Aktionen",
        "Cancel": "Abbrechen",
        "Created": "Erstellt am",
        "Date": "Eingeladen am",
        "Decline": "Ablehnen",
        "Department": "Abteilung",
        "EMail": "E-Mail",
        "Empty": "Keine offenen Einladungen",
        "EmptyEmployees": "Keine Mitarbeiter vorhanden",
        "Name": "Name",
        "Origin": "Herkunft",
        "OriginInvite": "Eingeladen",
        "OriginManual": "Manuell Angefragt",
        "OriginSystem": "Vom System erkannt",
        "Status": "Status",
        "StatusAccepted": "Aktzeptiert",
        "StatusDeclined": "Abgelehnt",
        "StatusWait": "Warten auf Bestätigung"
      }
    },
    "Configurations": {
      "AutoSave": {
        "Text1": "Ihre Konfiguration wird automatisch gespeichert",
        "Text2": "Anmelden um die automatische Speicherung zu aktivieren"
      },
      "ModalSelection": {
        "FooterText1": "Wir fertigen alle Schaltschränke auch in",
        "FooterTextSpan": "Edelstahl",
        "Main1": "Geben Sie hier die Dimensionen Ihres Schaltschrankes an.",
        "Main2": "Wählen Sie Ihren passenden Schaltschranktyp",
        "SelectButton": "Auswählen"
      },
      "ModelBox": {
        "Main1": "Gewähltes Modell"
      },
      "OptionBox": {
        "InfoText1": "Vielen dank für Ihre Interesse. Mit Ihrer Auswahl haben sie dazu beigetragen diese Features weiter zu entwickeln!",
        "InfoText2": "(Die oben genannten Features werden zur Zeit noch nicht eingebaut)",
        "Label1": "Schaltschrank Dach",
        "Label2": "Schaltschrank Sockel",
        "Label2Height": "Sockelhöhe",
        "Label3": "Innenliegende Scharniere",
        "Main1": "Optionen",
        "OkButton": "Ok",
        "WarningBoxHeadline": "Achtung",
        "WarningBoxText1": "Vielen dank für Ihre Interesse. Mit Ihrer Auswahl haben sie dazu beigetragen diese Features weiter zu entwickeln!",
        "WarningBoxText2": "Die ausgewählten Features werden zur Zeit noch nicht eingebaut."
      },
      "SelectedModal": {
        "Dimensions": "Maße der Montageplatte",
        "DimensionsHeight": "Höhe:",
        "DimensionsWidth": "Breite:",
        "Label1": "Interne Bezeichnung",
        "Label2": "Breite",
        "Label3": "Höhe",
        "Label4": "Tiefe",
        "Main1": "Abmessungen",
        "NoMountingPlate": "Dieses Modell beinhaltet keine Montageplatte"
      }
    },
    "Dashboard": {
      "Auth": {
        "ContinueConfiguration": {
          "ConfigColor": "Farbe:",
          "ConfigMaterial": "Material:",
          "ConfigName": "Name:",
          "ContinueButton": "Weiter Konfigurieren",
          "Header1": "Weiter Konfigurieren",
          "Sub1": "Ihren zuletzt konfigurieten Schlatschrank weiter Konfigurieren"
        },
        "LastConfigurations": {
          "ConfigAction": "Aktionen",
          "ConfigModel": "Model",
          "ConfigName1": "Name",
          "ConfigNr1": "Konfig-Nr.",
          "ConfigStatus": "Status",
          "ContinueButton": "Weiter",
          "Header1": "Ihre letzten Konfigurationen",
          "MineConfigsBtn": "Alle Konfigurationen",
          "ViewButton": "Ansehen"
        },
        "OrderConfiguration": {
          "Header1": "Schaltschrank",
          "Header2": "bestellen?",
          "OrderColor": "Farbe:",
          "OrderMaterial": "Material:",
          "OrderName": "Name:",
          "Sub1": "Ihren zuletzt konfigurieten Schlatschrank in wenigen Klicks bestellen",
          "checkButton": "Überprüfen und bestellen"
        },
        "Tipps": {
          "Header1": "Tipps aus der Armario Welt",
          "Link1": "https://www.fimab.eu/schaltschrank-konfigurator/der-schnelle-online-bestellprozess",
          "Link2": "https://www.fimab.eu/schaltschrank-konfigurator/software-fuer-die-schaltschrank-konstruktion",
          "Link3": "https://www.fimab.eu/schaltschrank-konfigurator/edelstahl-ausfuehrungen-bei-allen-schaltschraenken",
          "Link4": "https://www.fimab.eu/schaltschrank-konfigurator/schluss-mit-verstaubten-schaltschraenken",
          "Link5": "https://www.fimab.eu/schaltschrank-konfigurator/schaltschraenke-mit-ableitender-beschichtung-esd",
          "Link6": "https://www.fimab.eu/schaltschrank-konfigurator/nie-wieder-loecher-in-schaltschraenke-bohren",
          "LinkHeader1": "Der schnellste Online Bestellprozess",
          "LinkHeader2": "Konstruktionssoftware für Schaltschränke",
          "LinkHeader3": "Edelstahl Ausführungen bei allen Schaltschränken",
          "LinkHeader4": "Schluss mit verstaubten Schaltschränken",
          "LinkHeader5": "Schaltschränke mit ESD Lackierung",
          "LinkHeader6": "Nie wieder Löcher in Schaltschränke bohren",
          "Tipp1": "Modellauswahl",
          "Tipp2": "Lochbilder gestalten",
          "Tipp3": "Farbe und Material definieren",
          "Tipp4": "Bestellung aufgeben"
        }
      },
      "Header": {
        "CTAAuth": "Neue Konfiguration starten",
        "CTANoAuth": "Anmelden",
        "Main1": "ONLINE",
        "Main2": "SCHALTSCHRANK",
        "Main3": "KONFIGURATOR.",
        "Sub": "ARMARiO STEHT FÜR SCHNELLIGKEIT.",
        "Sub2": "IM PASSGENAUEN INDIVIDUELLEN SCHALTSCHRANKBAU.",
        "Text1": "Danach Bestelleingang. Der Vorteil für Sie: Der Schaltschrank wird komplett nach Ihren Angaben mit allen Bohrungen und Aussparungen geliefert. Sie sparen sich so eine aufwendige Nacharbeitungszeit und können gleich mit der Bestückung Ihrer vorgesehenen Anbaukomponenten beginnen..",
        "Text2": "ARMARiO - Sparen Sie Zeit und Kosten!"
      },
      "NoAuth": {
        "Advantages": {
          "Header1": "IHRE",
          "Header2": "VORTEILE",
          "Header3": "AUF",
          "Header4": "EINEM BLICK."
        },
        "FimabInfo": {
          "BtnText1": "Neue Konfiguration starten",
          "Header1": "FiMAB STEHT FÜR KOMPETENZ.",
          "Header2": "IN DER PRODUKTION VON ZUFRIEDENEN KUNDEN.",
          "ListHeader1": "FiMAB - Wir werden Sie begeistern!",
          "ListPoint1": "32 Jahre Erfahrung im Maschinenbau",
          "ListPoint2": "Familienunternehmen in der 2. Generation",
          "ListPoint3": "Mehr als 700 zufriedene Kunden in Deutschland und Europa",
          "ListPoint4": "Über 500 kundenspezifische Schaltschrank-Varianten ausgeliefert",
          "Main1": "KONFIGURIEREN",
          "Main2": "SIE",
          "Main3": "JETZT",
          "Main4": "IHREN",
          "Main5": "SCHALTSCHRANK",
          "Main6": "AUF",
          "Main7": "ARMARiO",
          "Text1": "Sie kennen das auch: “Gestern noch 20 Schaltschränke auf Lager bestellt und dann, gerade jetzt ändert der Lieferant für Frequenzumformer seine Produktlinie und von jetzt auf nachher passen die Geräte nicht mehr in die Schränke. Gerade mal eben einen vierstelligen Betrag für die Tonne eingelagert.",
          "Text2": "Im Co-Creation Prozess, mit unserem Schaltschrankkonfigurator, gestalten Sie als Nutzer Ihr Produkt genau so, wie Sie es benötigen. Bestellen Sie zum richtigen Zeitpunkt und in der Stückzahl, die Sie jetzt benötigen. Durch die interaktive Prozesskette - Sie gestalten den Wertschöpfungsprozess mit - und die automatisierten Schnittstellen verbinden wir die Vorteile aus zwei Welten. Die Vorteile der Massenfertigung und die der individuellen Einzelfertigung. Mass Customization bedeutet kundenspezifische Serienfertigung.",
          "Text3": "Wir bei FiMAB haben über 30 Jahre Erfahrung in der Produktion von Losgröße 1 für den Sondermaschinenbau. Daraus haben wir eine automatisierte Prozesskette vom Design bis hin zur Auslieferung entwickelt. Wir produzieren Losgröße 1 in Serie zu realistischen Preisen. Keine Überproduktion, keine Lagerhaltung, keine Verschwendung. Ein solcher interaktiver Kunden- Lieferanten-Prozess ist nicht nur ökonomisch, sondern auch ökologisch sinnvoll.",
          "TextBreak": "Unser Geheimtipp für Sie: Gehen Sie mit uns neue Wege und beeindrucken Sie Ihre Kollegen."
        },
        "Information": {
          "InfoStep1": "Zeitersparnis",
          "InfoStep2": "Kostenersparnis",
          "InfoStep3": "Optimale Passgröße",
          "InfoStep4": "Wir fertigen alle Schaltschränke auch in",
          "InfoStep4Bold": "Edelstahl",
          "TextBlock1": "Bestellen Sie bis",
          "TextBlock2": "16:00 Uhr",
          "TextBlock3": "und wir produzieren Ihren Schaltschrank bis zum"
        },
        "ReadyToInstall": {
          "Header1": "EINBAUFERTIGE",
          "Header2": "SCHALTSCHRÄNKE.",
          "StartConfigs": "Neue Konfiguration starten",
          "Text1": "Herzlichen Glückwunsch - die Tatsache, dass Sie sich jetzt die Zeit nehmen und diese Broschüre lesen, macht Sie schon jetzt zu einem Gewinner. Diese wenigen Minuten werden Ihrem Unternehmen nachhaltig Tage der Planung und der Nacharbeit ersparen.",
          "Text2": "Wir haben diese Broschüre zusammengestellt, um Ihnen zu zeigen, wie Sie in wenigen Minuten zu einem perfekten Schaltschrank kommen. Dadurch sparen Sie extrem viel Zeit und erhalten die optimale Lösung für Ihre Verpackungsmaschine. Anhand von Kundenergebnissen, erleben Sie diesen Prozess real und nachvollziehbar."
        },
        "Steps": {
          "Header1": "Variante und Dimension",
          "Header2": "Lochbilder",
          "Header3": "Material und Farbe",
          "Header4": "Bestellung aufgeben",
          "Main1": "NUR",
          "Main2": "4 SCHRITTE",
          "Main3": "ZUM PERFEKTEN",
          "Main4": "SCHALTSCHRANK.",
          "TextBox1": "Wählen Sie unter den Modellen eine passende Variante für Ihren Schaltschrank oder Klemmkasten aus. Definieren Sie die Art der Tür, sowie die Anschlagseite. Geben Sie danach die gewünschten Maße ein. Alle Modelle werden millimetergenau skaliert und passen somit genau in jedes Anlagenmodul.",
          "TextBox2": "Generieren Sie kinderleicht per Drag & Drop der vorgefertigten Anbaukomponenten jedes erdenkliche Lochbild. Der Konfigurator achtet dabei direkt auf die Plausibilität, prüft Kollisionen ab und zeigt diese gegebenenfalls an. Der digitale Zwilling des Schaltschranks sorgt dabei für die Funktionalität und ist Grundlage für eine normgerechte Ausführung.",
          "TextBox3": "Nur ein Klick entscheidet darüber, ob Ihr Schaltschrank in Stahlblech oder Edelstahl gefertigt wird.  Wählen Sie die Farbe und die Oberflächenbeschaffenheit völlig frei nach Ihrem Geschmack, dem CI Ihres Unternehmens oder dem Farbwunsch Ihres Kunden. Jeder Schaltschrank wird direkt in Ihrer Wunschfarbe produziert.",
          "TextBox4": "Nicht nur die Gestaltung, sondern auch die Bestellung können Sie direkt online, 24/7 von jedem Ort der Welt ausführen. Die Daten werden unmittelbar an unsere Produktion übermittelt, was eine kurze Produktionszeit garantiert. Dank des neuartigen B2B-Online-Bestellprozess können Sie auch Ihre Bestellanforderung an einen Kollegen, z. B. im Einkauf weiterleiten.",
          "TextHeader1": "Alle Gehäuse werden millimetergenau skaliert und passen somit in jede Lücke.",
          "TextHeader2": "Nie wieder müssen sie einen Schaltschrank mit der Bohrmaschine nachträglich bearbeiten.",
          "TextHeader3": "Sie sparen sich in jedem Fall den Vorgang des Umlackierens.",
          "TextHeader4": "10 Tage Produktionszeit für ein individuelles Schaltschrankgehäuse sind einmalig in der Branche.",
          "startConfigBtn": "Neue Konfiguration starten"
        },
        "Studies": {
          "ButtonText": "Die ganze Fallstudie lesen.",
          "CollapseHead1": "Ausgangssituation:",
          "CollapseHead2": "Herausforderung:",
          "CollapseHead3": "Die Lösung:",
          "CollapseHead4": "Auf lange Sicht:",
          "CollapseText1": "Hugo Beck Maschinenbau ist Spezialist für horizontale Flowpack-, Folien und Papierverpackungsmaschinen. Von früher war es Hugo Beck gewohnt, Standardschaltschränke zu kaufen und selbst Löcher zu bohren. Zur Vermeidung von aufwendiger Nacharbeit werden bereits interne Standardschränke hergestellt. Leider sind nicht in allen Fällen Standardschränke geeignet, selbst wenn sie nachbearbeitet werden.",
          "CollapseText2": "Der Schaltschrank sollte sehr klein sein. Gleichzeitig muss das Gehäuse aber auch sehr tief sein, da es einen Frequenzumformer in der Steuerung gibt. Hierfür gibt es so gut wie keine Standardlösungen. Abweichungen von den internen Standardgehäusen erfordern immer einen großen Aufwand in der Abstimmung und Herstellung. Es wurde eine Zeit und Kosten schonende Lösung gesucht. Eine aufwendige Nachbereitung sollte vermieden werden. Eine Sonderkonstruktion beim bisherigen Lieferanten erforderte gesonderte Zeichnungen und einen großen Abstimmungsprozess. Dieser Prozess kostet sehr viel Zeit und Geld. Nach vielen Zeichnungen und Experimenten ist Hugo Beck dann auf den ARMARiO aufmerksam geworden.",
          "CollapseText3": "Mit dem Online Konfigurator hat Hugo Beck nun eine Lösung, mit der die Firma die mechanische Konstruktion komplett außen vorlassen kann. Der Leiter der Elektrokonstruktion kann den gesamten Prozess der Gestaltung komplett selbst durchführen. Anschließend werden die Anforderungen mit allen Daten, wie Artikelnummer, Maße, Ausführung und Preis an den Einkauf weitergegeben. Für einen Elektriker, der einen Schrank mit Sondermaßen benötigt, eine sehr schöne Lösung. Zusätzlich sind genau die Komponenten zum Definieren der Lochbilder im System aufgeführt, die Hugo Beck in der täglichen Arbeit braucht. Selbst wenn ein exotisches Lochbild benötigt wird, kann das schnell und unkompliziert mit den Funktionen Rechteck und Kreis selbst erstellt werden. Auch das einfache Ändern von Stahl auf Edelstahl ist sehr komfortabel. Ein flexibles System, das Hugo Beck sehr schnell Resultate liefert. Immer wenn also Sonderlösungen benötigt werden, ist der Online Konfigurator das bevorzugte Tool.",
          "CollapseText4": "Der ARMARiO Schaltschrank ist zwar etwas teurer als der interne Standardschaltschrank, dafür entfallen aber alle zusätzlichen Kosten der Nacharbeit. Für einen Sonder-Schaltschrank ist die Ersparnis noch höher, da auch die Kosten der Planung und Konstruktion entfallen. Es gibt keine Experimente, keine langwierigen und abteilungsübergreifenden Dialoge und keine Überraschungen. Rundum eine schnelle Möglichkeit, komplett individualisierte Gehäuse in einer einmaligen Qualität zu bekommen. Mit dem neu entwickelten B2B Bestellprozess, also der Möglichkeit, die erstellte Konfiguration mit der Einkaufsabteilung zu teilen, ist es jetzt wirklich perfekt.",
          "CollapseText5": "Die FF Maschinen GmbH ist ein studentisches Konstruktionsbüro. Bei der Entwicklung von Maschinen wird von der Konzeption bis zur Produktentwicklung der gesamte Prozess angeboten. Der Fokus liegt dabei im Bereich Sondermaschinenbau unter anderem auch im Bereich Verpackungsmaschinen.",
          "CollapseText6": "In der Entwicklung wird der Fokus grundsätzlich auf die mechanische Konstruktion der Maschine gelegt und erst anschließend auf die Elektroplanung. Die Herausforderung besteht darin, zunächst einmal zu ermitteln, welche Steuerung, Antriebe usw. benötigt werden. Der Platz für die Schaltschränke wird in der Regel schon mit eingeplant, es passt dann aber meistens doch nicht ganz genau. Häufig passt also nur ein maßgeschneiderter Schaltschrank, insbesondere auch wegen der Tiefe von Umrichtern. Die Anforderungen an einen Schaltschrank sind grundsätzlich nicht besonders hoch, allerdings sind die passenden Ausschnitte für Schalter, Lüfter und Co häufig eine Herausforderung. Im besten Fall sollten diese schon fertig sein. Die Bohrmaschine musste in der Vergangenheit zu oft und zu kreativ eingesetzt werden.",
          "CollapseText7": "Der Konfigurator von ARMARiO führt unglaublich einfach durch die einzelnen Bearbeitungsschritte. In wenigen Minuten konnte ein passgenauer Schaltschrank erstellt werden. Komponenten wie Lüfter und Taster konnten visuell integriert werden, hierdurch wurde das individuelle Lochbild erstellt. Die Visualisierung des Schaltschranks konnte heruntergeladen und in die Planung der Anlage integriert werden. Neben der schnellen und einfachen Konstruktion hat auch die Lieferzeit von 10 Werktagen überzeugt.",
          "CollapseText8": "Die Firma FF Maschinen GmbH nutzt nun regelmäßig den ARMARiO und empfiehlt ihn begeistert weiter. Die unterschiedlichen Aufträge erfordern häufig individuelle Schaltschränke. Die einfache Bedienbarkeit und die schnelle Lieferung machen ARMARiO zu einem unverzichtbaren Partner. Durch die schnelle Lieferung kann eine höhere Flexibilität bei der Projektbearbeitung gelebt werden.",
          "Header1": "FALLSTUDIEN.",
          "TextBox1": "„Das ist für mich die einfachste Sache der Welt. So einfach, wie wenn ich einen Schütz kaufe.“",
          "TextBox2": "„Die Kontoverbindung von meinem Handy-Vertrag zu ändern, ist komplizierter als mit ARMARiO einen Schaltschrank zu konfigurieren.“",
          "TextBoxHeader1": "Hugo Beck Maschinenbau GmbH & Co. KG",
          "TextBoxHeader2": "FF Maschinenbau GmbH",
          "TextBoxSpan1": "Hugo Beck Maschinenbau GmbH & Co. KG, Dettingen, Gerhard Fink",
          "TextBoxSpan2": "FF Maschinen GmbH, Pforzheim, Felix Theurer"
        }
      }
    },
    "Helpers": {
      "OrderStatus": {
        "ANGEBOT": "Angebot",
        "BESTELLT": "Bestellt",
        "OFFEN": "Offen"
      }
    },
    "OrderConfirmation": {
      "AGBS": {
        "AGB": "AGB gelesen",
        "CancellationInfo": "Widerrufshinweise gelesen"
      },
      "Button1": "Okay",
      "Button2": "Kostenpflichtig Bestellen",
      "Button3": "Anmelden",
      "FormGroup": {
        "Label1": "Firma",
        "Label10": "Schaltschrank erstell von:",
        "Label11": "Datum:",
        "Label12": "Bitte geben Sie hier Ihre Bestell Nr. als individuelle Referenz an.",
        "Label2": "Postleitzahl",
        "Label3": "Ort",
        "Label4": "Straße + Hausnummer",
        "Label5": "Lieferadresse",
        "Label6": "Lieferadresse überschreiben",
        "Label7": "Stammdaten",
        "Label8": "Kundennummer:",
        "Label9": "Angebotsnummer:",
        "Save": "speichern"
      },
      "Head1": "Wir fertigen alle Schaltschränke auch in",
      "Head2": "Edelstahl",
      "Head3": "Rechnungsadresse",
      "Head4": "Rechnungsadresse überschreiben",
      "Head5": "Order",
      "Table": {
        "Head1": "Artikel Bezeichnung",
        "Head10": "19,00 % Mehrwertsteuer aus",
        "Head11": "EUR",
        "Head12": "Angebotsbetrag:",
        "Head2": "Menge",
        "Head3": "ME",
        "Head4": "Preis EUR",
        "Head5": "Wer EUR",
        "Head6": "Bezeichnung:",
        "Head7": "Artikel:",
        "Head8": "Stk",
        "Head9": "Nettobetrag"
      }
    },
    "Steps": {
      "Bar": {
        "Button1": "Andere Farbe wählen<",
        "Button2": "Weiter mit Ral 7035 Grobstruktur glänzend",
        "Main1": "Schrank",
        "Main2": "Komponenten",
        "Main3": "Farbe / Material",
        "Main4": "Abschluss",
        "Text1": "Du hast noch keine Farbe gewählt.",
        "Text2": "Bitte wähle eine Farbe aus.",
        "Text3": "Anmelden",
        "Text4": "Weiter"
      },
      "Colors": {
        "ColorHead": "Farbe",
        "CurrentColor": "Aktuelle Farbwahl:",
        "ESDColor": "ESD Farben",
        "LoadColor": "Farben werden geladen",
        "Main1": "Farbbeschichtung nach RAL",
        "MaterialOption1": "Stahlblech",
        "MaterialOption2": "Edelstahl",
        "RemoveColor": "Farbe entfernen",
        "StructurOption1": "Glatt glänzend",
        "StructurOption2": "Grobstruktur glänzend",
        "SubHeader1": "Material",
        "SubHeader2": "Struktur",
        "Surface": "Oberfläche: einseitig geschliffen K240"
      },
      "Components": {
        "CheckDeleteLayout1": "Wollen sie das Layout \"",
        "CheckDeleteLayout2": "wirklich löschen?",
        "CheckDeleteLayout3": "Nein!",
        "CheckDeleteLayout4": "Ja",
        "Circle": "Kreis",
        "ComponentLayoutSave": "Komponenten als Layout speichern",
        "ComponentName": "Komponenten:",
        "ComponentsBar1": "Komponenten",
        "ComponentsBar2": "Erweitert",
        "ComponentsBar3": "Layouts",
        "Datasheet": "Datenblatt",
        "Diameter": "Durchmesser:",
        "Distance": "Abstand",
        "ExpandedHead1": "Lineares Muster",
        "Height": "Höhe:",
        "Insert": "Einfügen",
        "LayoutInfo": "Es sind maximal 20 Komponenten erlaubt.",
        "LayoutName": "Layout Name",
        "Length": "Länge:",
        "LoadLayout": "Layout laden",
        "Main1": "Ihr Schaltschrank",
        "NewInfo": "NEU",
        "Number": "Anzahl:",
        "Optional": "(Optional)",
        "Rectangle": "Rechteck",
        "RequiredComponentText": "Bitte wählen Sie mindestens eine Komponente aus.",
        "Rotation": "Rotation:",
        "Rounding": "Rundung:",
        "Save": "Speichern",
        "SaveLayout": "Layout Speichern",
        "Size": "Größe:",
        "SubDepth": "Tiefe:",
        "SubHeight": "Höhe:",
        "SubWidth": "Breite:",
        "Width": "Breite:",
        "X": "X:",
        "Y": "Y:"
      },
      "ConfigurationsOverview": {
        "Head1": "Konfiguration löschen?\"",
        "Table": {
          "Head1": "Löschen?",
          "Head10": "Aktionen",
          "Head2": "Erstellt von",
          "Head3": "Status",
          "Head4": "Name",
          "Head5": "KonfigurationsNr",
          "Head6": "Model",
          "Head7": "Breite/Hoehe/Laenge",
          "Head8": "Material",
          "Head9": "Farbe",
          "Share": "Teilen",
          "View": "Ansehen"
        },
        "Text1": "Wollen sie ihre Konfiguration",
        "Text2": "wirklich löschen?"
      },
      "Finish": {
        "AddressHead1": "Abweichende Lieferadresse",
        "Button1": "Rechnungsadresse",
        "Button2": "Lieferadresse",
        "Button3": "Kostenpflichtig Bestellen",
        "Button4": "Als Vorlage Kopieren",
        "Button5": "Erneut Bestellen",
        "Button6": "Angebot Herunterladen",
        "Button7": "Zur Freigabe weiterleiten",
        "Button8": "Angebot erstellen",
        "Coating": "Beschichtung:",
        "ConfigurationNumber": "Konfigurationnummer:",
        "Depth": "Tiefe:",
        "DownloadHead1": "Angebot",
        "DownloadHead2": "erstellen",
        "DownloadHead3": "wurde erstellt",
        "Errors": {
          "AGB": "Sie müssen der AGB zustimmen",
          "AmountError": "Bitte geben Sie eine Stückzahl an",
          "ConfigurationError1": "Die Konfiguration gehört nicht zu Ihrem Account und ist nicht öffentlich erreichbar. Bitte loggen Sie sich ein oder erstellen Sie eine neue Konfiguration.",
          "ConfigurationError2": "Eingelogged mit: ",
          "NameError": "Bitte vergeben Sie eine interne Bezeichnung",
          "Revocation": "Sie müssen den Widerrufshinweisen zustimmen"
        },
        "Head1": "Gewähltes Modell",
        "Head2": "Farbe / Material",
        "Head3": "Lochbilder",
        "Head4": "Ansichten",
        "Head5": "Bestellen",
        "Head6": "Erneut Bestellen",
        "Height": "Höhe:",
        "InfoText1": "Es fallen eventuelle Mehrkosten an",
        "InfoText2": "Um diese Konfiguration zu bestellen oder ein Angebot zu erstellen, müssen Sie sich",
        "InfoText3": "Anmelden",
        "InfoText4": "Diese Konfiguration wurde bestellt.",
        "InfoText5": "Für diese Konfiguration wurde ein Angebot erstellt",
        "InfoText6": "und eine Freigabe an",
        "InfoText7": "geschickt",
        "InputLabel1": "Stückzahl",
        "InputLabel2": "Anmerkung",
        "InputLabel3": "Referenz",
        "InputLabel4": "Wunschliefertermin",
        "InputLabel5": "AGB gelesen",
        "InputLabel6": "Widerrufshinweise gelesen",
        "InputLabel7": "Angebotsnummer:",
        "Main1": "Bestellung prüfen und abschließen",
        "Material": "Material:",
        "ModalButton1": "Speichern",
        "ModalButton2": "Ok",
        "ModalHead1": "Bestellung für Freigabe weiterleiten",
        "ModalHead2": "Ansicht Schaltschrank",
        "ModalInfoText1": "Komponente",
        "ModalInfoText2": "Anzahl",
        "ModalInfoText3": "Nein, danke",
        "ModalInfoText4": "Angebot anfordern",
        "ModalInfoText5": "Okay",
        "ModalInfoText6": "Ihr Angebot wurde erstellt!",
        "ModalInfoText7": "Soll auch für die Komponenten in Ihrer Konfiguration ein Angebot erstellt werden?",
        "ModalInfoText8": "Ihr Angebot wurde angefordert!",
        "ModalInfoText9": "Die Komponenten wurden angefordert und ein Angebot wird Ihnen in Kürze zugeschickt!",
        "ModalLabel1": "Name",
        "ModalLabel2": "Firma",
        "ModalLabel3": "Postleitzahl",
        "ModalLabel4": "Ort",
        "ModalLabel5": "Straße + Hausnummer",
        "ModalLabel6": "E-Mail Adresse des Empfängers",
        "ModalLabel7": "Inhalt",
        "ModalLabel8": "Zusätzlicher Anhang:",
        "ModalSuccessText1": "Soll für die Komponenten in Ihrer Konfiguration ein Angebot erstellt werden?",
        "NoCoating": "keine Beschichtung",
        "NoHolePictures": "Keine Lochbilder",
        "NoStructur": "Keine Struktur gewählt",
        "NoViews": "Keine Ansichten verfügbar",
        "OrderHistory": "Bestellhistorie",
        "PaymentHead1": "Abweichende Rechnungsadresse",
        "Placeholder": {
          "Text1": "Anmerkung zur Bestellung..."
        },
        "Structur": "Stuktur:",
        "Surface": "Oberfläche:",
        "SurfaceText": "einseitig geschliffen K240",
        "Width": "Breite:"
      }
    }
  }], ["en-gb", {
    "AccountModal": {
      "Change": "Change",
      "Head1": "Edit account",
      "Head2": "PEdit profile picture",
      "Label1": "company",
      "Label2": "Account*",
      "Login": "Sign in",
      "LogoutMessage": "You have been logged out. You can register again here:",
      "MainAcc": {
        "CreateAccount": "Create an account",
        "Error": "Error",
        "ForgotPassword": "Forgot Password?",
        "Head1": "Sign in",
        "ResendPassword": "We have sent you an email containing instructions on how to reset your password.",
        "Success": "Success"
      },
      "Remove": "Remove",
      "RequiredFields": "* Required fields",
      "Save": "Save",
      "UploadImage": "Upload Image"
    },
    "BurgerMenu": {
      "AccountInformation": "Account Information",
      "Company": "Company administration",
      "DataProtection": "Datenschutz",
      "HelpAndSupport": {
        "Chat": "Support Chat",
        "FirewallSetting": "Firewall settings",
        "Head1": "Help and support",
        "Mail": "E-Mail: armario@fimab.eu",
        "Tel": "Support telephone: 07053 96839-65"
      },
      "Imprint": "Impressum",
      "Legal": {
        "DataProtection": "Data protection",
        "Header": "Legal",
        "Imprint": "Imprint"
      },
      "Login": "Sign in",
      "Logout": "Log out",
      "OrderHistory": "Order overview",
      "Support": {
        "FirewallSetting": "ARMARIO Firewall settings for companies",
        "Head1": "Support",
        "SuppChat": "Support chat",
        "SuppMail1": "Support email address:",
        "SuppMail2": "armario@fimab.eu",
        "SuppTel1": "Support phone number:",
        "SuppTel2": "+49 (7053) 96839-65"
      },
      "UserProfil": "User profile"
    },
    "Company": {
      "CompanyCard": {
        "Leave": "Leave"
      },
      "Employees": {
        "Header": "Employees"
      },
      "Header": "Your Company",
      "OpenInvites": {
        "Filter": "Status Filter",
        "FilterOptions": {
          "Accepted": "Accepted",
          "Declined": "Declined",
          "Open": "Open"
        },
        "Header": "Open Invites"
      },
      "OpenRequests": {
        "Filter": "Status Filter",
        "FilterOptions": {
          "Accepted": "Accepted",
          "Declined": "Declined",
          "Open": "Open"
        },
        "Header": "Open Requests"
      },
      "Table": {
        "Accept": "Accept",
        "Actions": "Actions",
        "Cancel": "Cancel",
        "Created": "Created at",
        "Date": "Invited at",
        "Decline": "Decline",
        "Department": "Department",
        "EMail": "E-Mail",
        "Empty": "No open Invites",
        "EmptyEmployees": "No Employees Found",
        "Name": "Name",
        "Origin": "Origin",
        "OriginInvite": "Invited",
        "OriginManual": "Manualy Requested",
        "OriginSystem": "Detected By System",
        "Status": "Status",
        "StatusAccepted": "Accepted",
        "StatusDeclined": "Declined",
        "StatusWait": "Wait for approval"
      }
    },
    "Configurations": {
      "AutoSave": {
        "Text1": "Your configuration will be saved automatically",
        "Text2": "Sign in to enable autosave"
      },
      "ModalSelection": {
        "FooterText1": "We also manufacture all control cabinets",
        "FooterTextSpan": "stainless steel",
        "Main1": "Enter the dimensions of your control cabinet here.",
        "Main2": "Choose your appropriate control cabinet type",
        "SelectButton": "Choose"
      },
      "ModelBox": {
        "Main1": "Selected model"
      },
      "OptionBox": {
        "InfoText1": "Thank you for your interest. With your selection you have contributed to the further development of these features!",
        "InfoText2": "(The above features are not currently being installed)",
        "Label1": "Switch cabinet roof",
        "Label2": "Switch cabinet socket",
        "Label2Height": "Socket height",
        "Label3": "Internal hinges",
        "Main1": "Options",
        "OkButton": "Ok",
        "WarningBoxHeadline": "Danger",
        "WarningBoxText1": "Thank you for your interest. With your selection you have contributed to the further development of these features!",
        "WarningBoxText2": "The selected features are not yet being installed."
      },
      "SelectedModal": {
        "Dimensions": "Dimensions of the mounting plate",
        "DimensionsHeight": "Height:",
        "DimensionsWidth": "Width:",
        "Label1": "The internal term",
        "Label2": "Width",
        "Label3": "Height",
        "Label4": "depth",
        "Main1": "Dimensions",
        "NoMountingPlate": "This model does not include a mounting plate"
      }
    },
    "Dashboard": {
      "Auth": {
        "ContinueConfiguration": {
          "ConfigColor": "Color:",
          "ConfigMaterial": "Material:",
          "ConfigName": "Name:",
          "ContinueButton": "continue configuring",
          "Header1": "Continue configuring",
          "Sub1": "Continue configuring your last configured control cabinet"
        },
        "LastConfigurations": {
          "ConfigAction": "Actions",
          "ConfigModel": "Model",
          "ConfigName1": "Name",
          "ConfigNr1": "Config no.",
          "ConfigStatus": "Status",
          "ContinueButton": "Continue",
          "Header1": "Your last configurations",
          "MineConfigsBtn": "All configurations",
          "ViewButton": "View"
        },
        "OrderConfiguration": {
          "Header1": "Control cabinet",
          "Header2": "order?",
          "OrderColor": "Color:",
          "OrderMaterial": "Material:",
          "OrderName": "Name:",
          "Sub1": "Order your last configured control cabinet in just a few clicks",
          "checkButton": "Check and order"
        },
        "Tipps": {
          "Header1": "Tips from the Armario world",
          "Link1": "https://www.fimab.eu/schaltschrank-konfigurator/der-schnelle-online-bestellprozess",
          "Link2": "https://www.fimab.eu/schaltschrank-konfigurator/software-fuer-die-schaltschrank-konstruktion",
          "Link3": "https://www.fimab.eu/schaltschrank-konfigurator/edelstahl-ausfuehrungen-bei-allen-schaltschraenken",
          "Link4": "https://www.fimab.eu/schaltschrank-konfigurator/schluss-mit-verstaubten-schaltschraenken",
          "Link5": "https://www.fimab.eu/schaltschrank-konfigurator/schaltschraenke-mit-ableitender-beschichtung-esd",
          "Link6": "https://www.fimab.eu/schaltschrank-konfigurator/nie-wieder-loecher-in-schaltschraenke-bohren",
          "LinkHeader1": "The fastest online ordering process",
          "LinkHeader2": "Design software for control cabinets",
          "LinkHeader3": "Stainless steel versions for all control cabinets",
          "LinkHeader4": "No more dusty control cabinets",
          "LinkHeader5": "Control cabinets with ESD coating",
          "LinkHeader6": "Never drill holes in control cabinets again",
          "Tipp1": "Model selection",
          "Tipp2": "Design hole patterns",
          "Tipp3": "Define color and material",
          "Tipp4": "Place an order"
        }
      },
      "Header": {
        "CTAAuth": "Start new configuration",
        "CTANoAuth": "Sign in",
        "Main1": "ONLINE",
        "Main2": "CONTROL CABINET",
        "Main3": "CONFIGURATOR.",
        "Sub": "ARMARiO STANDS FOR QUICKNESS.",
        "Sub2": "IN CUSTOMIZED INDIVIDUAL CONTROL CABINET CONSTRUCTION.",
        "Text1": "Then order received. The advantage for you: The control cabinet is delivered completely according to your specifications with all holes and recesses. This saves you time-consuming rework and allows you to start assembling your intended add-on components straight away.",
        "Text2": "ARMARiO - Save time and money!"
      },
      "NoAuth": {
        "Advantages": {
          "Header1": "YOUR",
          "Header2": "BENEFITS",
          "Header3": "AT",
          "Header4": "A GLANCE."
        },
        "FimabInfo": {
          "BtnText1": "Start new configuration",
          "Header1": "FiMAB STANDS FOR COMPETENCE.",
          "Header2": "IN THE PRODUCTION OF SATISFIED CUSTOMERS.",
          "ListHeader1": "FiMAB - We will inspire you!",
          "ListPoint1": "32 years of experience in mechanical engineering",
          "ListPoint2": "Family business in the 2nd generation",
          "ListPoint3": "More than 700 satisfied customers in Germany and Europe",
          "ListPoint4": "Over 500 customer-specific control cabinet variants delivered",
          "Main1": "CONFIGURE",
          "Main2": "NOW",
          "Main3": "YOUR",
          "Main4": "OWN",
          "Main5": "CONTROL CABINET",
          "Main6": "ON",
          "Main7": "ARMARiO",
          "Text1": "You know it too: “Yesterday, 20 control cabinets were ordered in stock and then, right now, the supplier of frequency converters is changing its product line and from now on the devices no longer fit in the cabinets. Just a four-figure amount for the ton stored.",
          "Text2": "In the co-creation process, with our control cabinet configurator, you as the user design your product exactly as you need it. Order at the right time and in the quantity you need now. Through the interactive process chain - you help shape the value creation process - and the automated interfaces, we combine the advantages of two worlds. The advantages of mass production and those of individual one-off production. Mass customization means customer-specific series production.",
          "Text3": "We at FiMAB have over 30 years of experience in the production of batch size 1 for special machine construction. From this we have developed an automated process chain from design to delivery. We produce batch size 1 in series at realistic prices. No overproduction, no storage, no waste. Such an interactive customer-supplier process makes sense not only economically, but also ecologically.",
          "TextBreak": "Our insider tip for you: take new paths with us and impress your colleagues."
        },
        "Information": {
          "InfoStep1": "Time saving",
          "InfoStep2": "Cost savings",
          "InfoStep3": "Optimal size",
          "InfoStep4": "We also produce all control cabinets in",
          "InfoStep4Bold": "stainless steel",
          "TextBlock1": "Order by",
          "TextBlock2": "4:00 p.m",
          "TextBlock3": "and we will produce your control cabinet by"
        },
        "ReadyToInstall": {
          "Header1": "READY TO INSTALL",
          "Header2": "CONTROL CABINETS.",
          "StartConfigs": "Start new configuration",
          "Text1": "Congratulations - the fact that you're taking the time to read this brochure already makes you a winner. These few minutes will save your company days of planning and rework.",
          "Text2": "This brochure to show you how you can get the perfect control cabinet in just a few minutes. This saves you a lot of time and gives you the optimal solution for your packaging machine. Using customer results, you will experience this process in a real and understandable way."
        },
        "Steps": {
          "Header1": "Variant and dimension",
          "Header2": "Hole patterns",
          "Header3": "Material and color",
          "Header4": "Place an order",
          "Main1": "ONLY",
          "Main2": "4 STEPS",
          "Main3": "TO THE PERFECT",
          "Main4": "CONTROL CABINET.",
          "TextBox1": "Select a suitable variant for your control cabinet or terminal box from the models. Define the type of door and the hinge side. Then enter the desired dimensions. All models are scaled to the millimeter and therefore fit exactly into every system module.",
          "TextBox2": "Generate every conceivable hole pattern by dragging and dropping the prefabricated components. The configurator pays direct attention to plausibility, checks for collisions and displays them if necessary. The digital twin of the control cabinet ensures functionality and is the basis for a standard-compliant design.",
          "TextBox3": "Just one click decides whether your control cabinet is made of sheet steel or stainless steel. Choose the color and surface texture freely according to your taste, the CI of your company or the color request of your customer. Each control cabinet is produced directly in your desired color.",
          "TextBox4": "Not only can you design, but you can also place orders directly online, 24/7 from anywhere in the world. The data is transmitted directly to our production, which guarantees a short production time. Thanks to the new B2B online ordering process, you can also send your order request to a colleague, e.g. B. forward in purchasing.",
          "TextHeader1": "All control cabinets are scaled to the millimeter and therefore fit into any gap.",
          "TextHeader2": "You will never have to edit a control cabinet with a drill again.",
          "TextHeader3": "In any case, you save yourself the repainting process.",
          "TextHeader4": "10 days of production time for an individual control cabinet housing is unique in the industry.",
          "startConfigBtn": "Start new configuration"
        },
        "Studies": {
          "ButtonText": "Read the entire case study.",
          "CollapseHead1": "initial situation:",
          "CollapseHead2": "Challenge:",
          "CollapseHead3": "The solution:",
          "CollapseHead4": "Long-term:",
          "CollapseText1": "Hugo Beck Maschinenbau is a specialist in horizontal flowpack, film and paper packaging machines. In the past, Hugo Beck was used to buying standard control cabinets and drilling holes himself. To avoid time-consuming rework, internal standard cabinets are already manufactured. Unfortunately, standard cabinets are not suitable in all cases, even if they are refinished.",
          "CollapseText2": "The control cabinet should be very small. At the same time, the housing also has to be very deep because there is a frequency converter in the control. There are almost no standard solutions for this. Deviations from the internal standard housings always require a great deal of effort in coordination and production. A time and cost saving solution was sought. Time-consuming follow-up work should be avoided. A special design from the previous supplier required separate drawings and a major coordination process. This process costs a lot of time and money. After many drawings and experiments, Hugo Beck became aware of the ARMARiO.",
          "CollapseText3": "With the online configurator, Hugo Beck now has a solution that allows the company to completely ignore the mechanical construction. The head of electrical design can carry out the entire design process completely himself. The requirements are then passed on to purchasing with all data such as item number, dimensions, design and price. A very nice solution for an electrician who needs a cabinet with special dimensions. In addition, the exact components for defining the hole patterns in the system that Hugo Beck needs in his daily work are listed. Even if an exotic hole pattern is required, this can be created quickly and easily using the rectangle and circle functions. Simply changing from steel to stainless steel is also very convenient. A flexible system that Hugo Beck delivers results very quickly. Whenever special solutions are required, the online configurator is the preferred tool.",
          "CollapseText4": "The ARMARiO control cabinet is slightly more expensive than the internal standard control cabinet, but there are no additional costs for rework. The savings are even greater for a special control cabinet, as the planning and construction costs are also eliminated. There are no experiments, no lengthy and cross-departmental dialogues and no surprises. All in all, a quick way to get completely customized housings in unique quality. With the newly developed B2B ordering process, i.e. the ability to share the created configuration with the purchasing department, it is now really perfect.",
          "CollapseText5": "The FF Maschinen GmbH is a student design office. When developing machines, the entire process is offered, from conception to product development. The focus is on the area of ​​special machine construction, including packaging machines.",
          "CollapseText6": "During development, the focus is generally on the mechanical design of the machine and only then on the electrical planning. The challenge is to first determine which controls, drives, etc. are needed. The space for the control cabinets is usually already planned, but it usually doesn't fit exactly. Often only a tailor-made control cabinet is suitable, especially because of the depth of the converters. The requirements for a control cabinet are generally not particularly high, but finding the right cutouts for switches, fans, etc. is often a challenge. In the best case scenario, these should already be finished. In the past, the drill had to be used too often and too creatively.",
          "CollapseText7": "The ARMARiO configurator guides you through the individual processing steps incredibly easily. A precisely fitting control cabinet could be created in just a few minutes. Components such as fans and buttons could be visually integrated, thereby creating the individual hole pattern. The visualization of the control cabinet could be downloaded and integrated into the planning of the system. In addition to the quick and easy construction, the delivery time of 10 working days was also impressive.",
          "CollapseText8": "The Company FF Maschinen GmbH now uses the ARMARiO regularly and recommends it enthusiastically. The different orders often require individual control cabinets. The ease of use and fast delivery make ARMARiO an indispensable partner. The fast delivery means greater flexibility can be achieved in project processing.",
          "Header1": "CASE STUDIES.",
          "TextBox1": "“This is the easiest thing in the world for me. As easy as buying a contactor.”",
          "TextBox2": "“Changing the account details of my cell phone contract is more complicated than configuring a control cabinet with ARMARiO.”",
          "TextBoxHeader1": "Hugo Beck Maschinenbau GmbH & Co. KG",
          "TextBoxHeader2": "FF Maschinenbau GmbH",
          "TextBoxSpan1": "Hugo Beck Maschinenbau GmbH & Co. KG, Dettingen, Gerhard Fink",
          "TextBoxSpan2": "FF Maschinen GmbH, Pforzheim, Felix Theurer"
        }
      }
    },
    "Helpers": {
      "OrderStatus": {
        "ANGEBOT": "Offer",
        "BESTELLT": "Ordered",
        "OFFEN": "Open"
      }
    },
    "OrderConfirmation": {
      "AGBS": {
        "AGB": "Read the terms and conditions",
        "CancellationInfo": "Read cancellation instructions"
      },
      "Button1": "Okay",
      "Button2": "Order for a fee",
      "Button3": "Sign in",
      "FormGroup": {
        "Label1": "company",
        "Label10": "Control cabinet created by:",
        "Label11": "Date:",
        "Label12": "Please enter your order number here as an individual reference.",
        "Label2": "Postal code",
        "Label3": "Location",
        "Label4": "Street + house number",
        "Label5": "delivery address",
        "Label6": "Overwrite delivery address",
        "Label7": "base data",
        "Label8": "Customer number:",
        "Label9": "Offer number:",
        "Save": "save"
      },
      "Head1": "We also manufacture all control cabinets",
      "Head2": "stainless steel",
      "Head3": "Billing address",
      "Head4": "Overwrite billing address",
      "Head5": "Order",
      "Table": {
        "Head1": "Item description",
        "Head10": "19,00 % VAT",
        "Head11": "EUR",
        "Head12": "Offer amount:",
        "Head2": "Crowd",
        "Head3": "ME",
        "Head4": "Price EUR",
        "Head5": "Who EUR",
        "Head6": "Designation:",
        "Head7": "Article:",
        "Head8": "Stk",
        "Head9": "net amount"
      }
    },
    "Steps": {
      "Bar": {
        "Button1": "Choose another color<",
        "Button2": "Continue with Ral 7035 coarse structure, shiny",
        "Main1": "Cabinet",
        "Main2": "Components",
        "Main3": "Color / Material",
        "Main4": "Finish",
        "Text1": "You haven't chosen a color yet.",
        "Text2": "Please select a color.",
        "Text3": "Sign in",
        "Text4": "Next"
      },
      "Colors": {
        "ColorHead": "Color",
        "CurrentColor": "Current color choice:",
        "ESDColor": "ESD colors",
        "LoadColor": "Colors are loading",
        "Main1": "Color coating according to RAL",
        "MaterialOption1": "Sheet steel",
        "MaterialOption2": "stainless steel",
        "RemoveColor": "Remove paint",
        "StructurOption1": "Smooth shiny",
        "StructurOption2": "Coarse structure shiny",
        "SubHeader1": "Material",
        "SubHeader2": "structure",
        "Surface": "Surface: ground on one side K240"
      },
      "Components": {
        "CheckDeleteLayout1": "Do you want the layout? \"",
        "CheckDeleteLayout2": "really delete?",
        "CheckDeleteLayout3": "No!",
        "CheckDeleteLayout4": "Yes",
        "Circle": "Circle",
        "ComponentLayoutSave": "Save components as layout",
        "ComponentName": "Components:",
        "ComponentsBar1": "Components",
        "ComponentsBar2": "Advanced",
        "ComponentsBar3": "Layouts",
        "Datasheet": "data sheet",
        "Diameter": "diameter:",
        "Distance": "Distance",
        "ExpandedHead1": "Linear pattern",
        "Height": "Height:",
        "Insert": "Insert",
        "LayoutInfo": "A maximum of 20 components are allowed.",
        "LayoutName": "Layout Name",
        "Length": "length:",
        "LoadLayout": "Load layout",
        "Main1": "Your switch cabinet",
        "NewInfo": "NEW",
        "Number": "Number:",
        "Optional": "(Optional)",
        "Rectangle": "rectangle",
        "RequiredComponentText": "Please select at least one component.",
        "Rotation": "Rotation:",
        "Rounding": "rounding:",
        "Save": "Save",
        "SaveLayout": "Save layout",
        "Size": "Size:",
        "SubDepth": "depth:",
        "SubHeight": "Height:",
        "SubWidth": "Width:",
        "Width": "Width:",
        "X": "X:",
        "Y": "Y:"
      },
      "ConfigurationsOverview": {
        "Head1": "Delete configuration?\"",
        "Table": {
          "Head1": "Delete?",
          "Head10": "Actions",
          "Head2": "Created by",
          "Head3": "Status",
          "Head4": "Name",
          "Head5": "Configuration no.",
          "Head6": "Model",
          "Head7": "Width/height/length",
          "Head8": "Material",
          "Head9": "Color",
          "Share": "Share",
          "View": "View"
        },
        "Text1": "Do you want your configuration?",
        "Text2": "really delete?"
      },
      "Finish": {
        "AddressHead1": "Different delivery address",
        "Button1": "Billing address",
        "Button2": "delivery address",
        "Button3": "Place Order",
        "Button4": "Copy as a template",
        "Button5": "Re-order",
        "Button6": "Download offer",
        "Button7": "Forward for approval",
        "Button8": "Create offer",
        "Coating": "Coating:",
        "ConfigurationNumber": "Configuration number:",
        "Depth": "Depth:",
        "DownloadHead1": "Offer",
        "DownloadHead2": "create",
        "DownloadHead3": "was created",
        "Errors": {
          "AGB": "You must agree to the terms and conditions",
          "AmountError": "Please enter a quantity",
          "ConfigurationError1": "The configuration is not part of your account and is not publicly accessible. Please log in or create a new configuration.",
          "ConfigurationError2": "Logged in with:",
          "NameError": "Please enter an internal name",
          "Revocation": "You must agree to the cancellation instructions"
        },
        "Head1": "Selected model",
        "Head2": "Color / Material",
        "Head3": "Hole patterns",
        "Head4": "Views",
        "Head5": "Order",
        "Head6": "Re-order",
        "Height": "Height:",
        "InfoText1": "Additional costs may apply",
        "InfoText2": "To order this configuration or create a quote, you must",
        "InfoText3": "Sign in",
        "InfoText4": "This configuration has been ordered.",
        "InfoText5": "An offer has been created for this configuration",
        "InfoText6": "and a release",
        "InfoText7": "sent",
        "InputLabel1": "Number of pieces",
        "InputLabel2": "Reference",
        "InputLabel3": "Note",
        "InputLabel4": "Desired delivery date",
        "InputLabel5": "Read the terms and conditions",
        "InputLabel6": "Read cancellation instructions",
        "InputLabel7": "Offer number:",
        "Main1": "Check and complete your order",
        "Material": "Material:",
        "ModalButton1": "Save",
        "ModalButton2": "Ok",
        "ModalHead1": "Forward order for approval",
        "ModalHead2": "View of the control cabinet",
        "ModalInfoText1": "component",
        "ModalInfoText2": "Number",
        "ModalInfoText3": "No, thank you",
        "ModalInfoText4": "Request a quote",
        "ModalInfoText5": "Okay",
        "ModalInfoText6": "Your offer has been created!",
        "ModalInfoText7": "Should an offer also be created for the components in your configuration?",
        "ModalInfoText8": "Your offer has been requested!",
        "ModalInfoText9": "The components have been requested and a quote will be sent to you shortly!",
        "ModalLabel1": "Name",
        "ModalLabel2": "company",
        "ModalLabel3": "Postal code",
        "ModalLabel4": "Location",
        "ModalLabel5": "Street + house number",
        "ModalLabel6": "Recipient's e-mail address",
        "ModalLabel7": "Contents",
        "ModalLabel8": "Additional attachment:",
        "ModalSuccessText1": "Should an offer be created for the components in your configuration?",
        "NoCoating": "no coating",
        "NoHolePictures": "No hole patterns",
        "NoStructur": "no structure chosen",
        "NoViews": "No views available",
        "OrderHistory": "Order history",
        "PaymentHead1": "Different billing address",
        "Placeholder": {
          "Text1": "Note on order..."
        },
        "Structur": "structure:",
        "Surface": "Oberfläche:",
        "SurfaceText": "ground on one side K240",
        "Width": "Width:"
      }
    }
  }]];
  _exports.default = _default;
});